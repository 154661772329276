import React from "react";

interface ParagraphProps {
  title: string;
  children: any;
}

const Paragraph: React.FC<ParagraphProps> = ({ title, children }) => {
  return (
    <>
      <h2 className="mb-1 text-4xl font-bold heading">{title}</h2>
      <p className="mb-3">{children}</p>
    </>
  );
};

export default React.memo(Paragraph);
