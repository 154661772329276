import * as React from "react";
import { ROUTE_LINKS } from "../utils/routes";
import PageWrapper from "../components/PageWrapper";
import MatrixModelImg from "../images/matrix-model.png";
import Paragraph from "../components/Paragraph";
import SEO from "../components/SEO";
import Button from "../components/Button";
import EmbedModal from "../components/EmbedModal";
import { HUBSPOT_FORMS } from "../utils/constants";

const ManagerLanding: React.FC = () => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [dialogData, setDialogData] = React.useState({
    embedUrl: "",
    header: "",
  });

  const closeDialog = () => {
    setDialogVisible(false);
  };

  return (
    <PageWrapper path={ROUTE_LINKS.managerLanding}>
      <SEO pageUrl={ROUTE_LINKS.managerLanding} />
      <main className="max-w-6xl flex flex-col md:mx-auto">
        <div className="flex flex-col self-center md:flex-row  md:items-center p-2.5 pb-12 justify-between min-h-[700px]  md:min-h-[555px]">
          <div className="w-full md:w-5/12">
            <Paragraph title="The Problem">
              <span className="font-bold text-lg">
                Tenant screening is a complicated process.&nbsp;
              </span>
              We’ve done the heavy lifting of gathering and verifying
              information from different sources. The data comes in different
              formats, some easier to use than others. The average package
              includes 45 pages. A single person can only work on a finite
              number of tasks at a time, making the screening process long
              and&nbsp;
              <strong>tedious</strong>.
            </Paragraph>
            <q className="text-xl text-slate-700 font-bold my-8 mx-8">
              There are over 48 million rental homes in the United States 35% of
              Americans rent homes. The average tenant screening takes 3-5
              business days-Matrix Rental Solutions does it in less than 15
              minutes.
            </q>
            <br /> <br />
            <Paragraph title="Our Solution">
              Our proprietary scoring model called MAIA (Matrix Asset &amp;
              Income Analysis) produces a more thorough, inclusive tenant
              scoring that allows landlords and property managers to underwrite
              and approve tenants&nbsp;<strong>in less than 15 minutes.</strong>
            </Paragraph>
          </div>
          <div className="w-full md:w-1/2 self-start">
            <img
              src={MatrixModelImg}
              alt="Matrix Model"
              title="Matrix Model"
              className="mx-auto"
            />
          </div>
        </div>
        <div className="mt-4">
          <Paragraph title="Be seen by potential renters">
            To learn how you can get on our platform or to request API
            documentation – please fill out the forms below.
          </Paragraph>
          <Button
            type="button"
            className="py-1 px-4 mt-5"
            onClick={() => {
              setDialogData({
                embedUrl: HUBSPOT_FORMS.managerLearnMore,
                header: "Learn More",
              });
              setDialogVisible(true);
            }}
          >
            Learn More
          </Button>
          <br />
          <Button
            type="button"
            className="py-1 px-4 mt-5"
            onClick={() => {
              setDialogData({
                embedUrl: HUBSPOT_FORMS.apiDocs,
                header: "Request API Documentation",
              });
              setDialogVisible(true);
            }}
          >
            Request API Documentation
          </Button>
        </div>
      </main>
      <EmbedModal
        data={dialogData}
        isVisible={dialogVisible}
        setIsVisible={closeDialog}
      />
    </PageWrapper>
  );
};

export default React.memo(ManagerLanding);
